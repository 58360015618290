body {
  color: #353535;
  text-align: center;
}

a, a:hover, a:visited {
  color: #1E90FF
}

.menu {
  text-align: center;
}

.headshot {
  margin-top: 150px;
  max-width: 200px;
  border-radius: 100%;
  box-shadow: 2px 2px 2px rgb(183 183 183);
}

.name {
  margin-top: 0px;
  margin-bottom: 0px;
}
.blurb {
  /* margin-top: 10px; */
  font-size: 1.15em;
}
.lfw {
  /* font-style: italic; */
  margin-top: 30px;
}

.lfw-subtext {
  font-size: 0.85em;
}

.menu-items {
  /* margin-top: 30px; */
}

.menu-item {
  margin-top: 10px;
}

.social-item {
  display: inline-block;
  margin-right: 10px;
}
.social-item:last-child {
  margin-right: 0px;
}

.social-item-img {
  height: 22px;
  width: 22px;
}
